import React from 'react'

const PrivacyPolicy = () => {
    return (
        <section className="terms">
            <div className="row">
                <h2>PRIVACY POLICY</h2>
            </div>

            <div className="row content">
                <div>
                    <p><strong>What information do we collect?</strong></p>
                    <p>We collect information from you when you register, place an order or fill out a form on the LYRACARE website.</p>
                    <p>When ordering or registering, as appropriate, you may be asked to enter your: name, e-mail address, mailing address, phone number, or credit card information. You may also visit our site anonymously.</p>
                    <p>Google, as a third-party vendor, uses cookies to serve ads on your site. Google's use of these cookies enables it to serve ads to your users based on their visits to your sites and other sites online. Users may opt-out of the use of the DART cookie by visiting the Google ad and content network privacy policy.</p>
                    <p>&nbsp;</p>
                    <p><strong>What do we use your information for?</strong></p>
                    <p>Any of the information we collect from you may be used in one of the following ways:</p>
                    <ul>
                        <li>To customize your experience (your information helps us to respond to your individual needs better)</li>
                        <li>To enhance customer service (your information helps us to more effectively react to your customer service requests and needs)</li>
                        <li>To process orders and transactions (your information, whether public or private, will not be sold, exchanged, transferred, or shared to any other company for any reason without your consent, other than for the earnest purpose of delivering the purchased product or service requested.)</li>
                    </ul>
                    <p><strong>The Personal Information We Collect:</strong></p>
                    <p>We want you to understand how the personal information you provide to us is collected and used. We collect and store your personal information when you provide it to us or to our service providers, such as in the following situations:</p>
                    <p><strong>Transactions:</strong></p>
                    <p>We or our service providers collect personal information from you when you enter into a transaction with us and/or such service providers.</p>
                    <p>Registration: We may collect your personal information when you order your medication from LYRACARE.</p>
                    <p>Payment (Billing) Information:</p>
                    <p>For orders and/or purchases, we may ask for credit card information, bank information, or cheque information.</p>
                    <p><strong>Storage:</strong></p>
                    <p>If you choose to create an online account, we will store billing and shipping information as a convenience for you for future purchases, and we will store information about your orders for order tracking and status retrieval purposes.</p>
                    <p><strong>Contact Information:</strong></p>
                    <p>LYRACARE collects personal information from users of the LYRACARE website who are interested in receiving information about our products or services, such as e-mail alerts, newsletters, calls, and other notifications.</p>
                    <p><strong>Use and Disclosure of Personal Information:</strong></p>
                    <p>We use your personal information to respond to your requests, such as to fulfill your order, contact you with information about your order, send you email alerts, send you newsletters, and provide you with related customer service. To the extent permitted by applicable law, we may provide personal information to our affiliated businesses or to our business partners, who may use it to send you marketing and other communications.</p>
                    <p>We may disclose personal information to our service providers, who provide services such as website hosting, data analysis, payment processing, order fulfillment information technology and related infrastructure provision, customer service, email delivery, auditing, and other services.</p>
                    <p>If we are requested by law enforcement officials or judicial authorities to provide personal information, we may do so. In matters involving claims of personal or public safety or in litigation where the information is pertinent (including to allow us to pursue available remedies or limit the damages that we may sustain), we may use or disclose personal information, including without court process. We may also use or disclose personal information to enforce our terms and conditions, to protect our operations or those of any of our affiliates, or to protect our rights, privacy, safety, or property and/or that of our affiliates, you, or others.</p>
                    <p>We may use and disclose personal information to investigate security breaches or otherwise cooperate with authorities pursuant to a legal matter.</p>
                    <p>We may use and disclose information that does not personally identify you (including the information described under “Cookies and Other Technologies,” below) for any purpose, except to the extent limited by applicable law. If we are required to treat such information as personal information under applicable law, then we may use it for all the purposes for which we use and disclose personal information.</p>
                    <p>We may combine information that does not personally identify you with personal information. If we do, we will treat the combined information as personal information as long as it is combined.</p>
                    <p><strong>How do we protect your information?</strong></p>
                    <p>We implement a variety of security measures to maintain the safety of your personal information when you place an order, submit, or access your personal information on our website.</p>
                    <p>&nbsp;</p>
                    <p><strong>Do we use cookies?</strong></p>
                    <p>Yes, we use cookies to help us remember and process the items you ordered and compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.</p>
                    <p>If you prefer, you can choose to have your computer prompt you each time a cookie is being sent, or you can choose to turn off all cookies via your browser settings. Like most websites, if you turn your cookies off, some of our services may not function completely. However, you can still place orders over the telephone or by contacting customer service.</p>
                    <p>&nbsp;</p>
                    <p><strong>Do we disclose any information to outside parties?</strong></p>
                    <p>No, we do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. Note, however, that this does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.</p>
                    <p>We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect our or others’ rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</p>
                    <p>&nbsp;</p>
                    <p><strong>California Online Privacy Protection Act Compliance</strong></p>
                    <p>We value your privacy, which is why we have taken the necessary precautions to be in compliance with the California Online Privacy Protection Act. We will not distribute your personal information to outside parties without your consent.</p>
                    <p><strong>Children’s Online Privacy Protection Act Compliance</strong></p>
                    <p>We are also in compliance with the requirements of COPPA (Children’s Online Privacy Protection Act), and we do not collect any information from anyone under 13 years of age. Our website, products, and services are all directed to people who are at least 18 years old or older.</p>
                    <p>&nbsp;</p>
                    <p><strong>Online Privacy Policy Only</strong></p>
                    <p>This online privacy policy applies only to information collected through our website and not to information collected offline.</p>
                    <p>By using, you agree to our website’s privacy policy.</p>
                    <p>&nbsp;</p>
                    <p><strong>Changes to Our Privacy Policy</strong></p>
                    <p>If we decide to change our privacy policy, we will post all changes on this page.</p>
                </div>
            </div>
        </section>
    )
}

export default PrivacyPolicy;