import { tryFetch } from "./FetchAPI"

export const handleInit = async () => {
    let params = {
        url: `/api/v1/init`,
        method: "GET",
        payload: {},
    }
    return await tryFetch(params);
}

export const getStates = async () => {
    let params = {
        url: `/api/v1/states`,
        method: "GET",
        payload: {},
    }
    return await tryFetch(params);
}

export const getCities = async (state) => {
    let params = {
        url: `/api/v1/cities/${state}`,
        method: "GET",
        payload: {},
    }
    return await tryFetch(params);
}

export const placeOrder = async (payload) => {
    let params = {
        url: `/api/v1/order`,
        method: "POST",
        payload: payload,
    }
    return await tryFetch(params);
}

export const sendQuery = async (payload) => {
    let params = {
        url: `/api/v1/contact`,
        method: "POST",
        payload: payload,
    }
    return await tryFetch(params);
}