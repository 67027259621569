import React from 'react'

const About = () => {

    return (
        <section className="about" id="about">

            <div className="row">
                <h2>ABOUT US</h2>
                <p>
                We are a tech company with a platform that provides an integrated gateway for doctors, patients, and dispensary pharmacies. 
                Lyracare is your hassle-free, one-stop platform for your safe and secure online purchases; ensuring seamless processing of all your needs for high quality medication at the lowest prices. 
                </p>

                <div className="features">
                    <div className="col">
                        <span className="material-symbols-outlined">
                            local_shipping
                        </span>
                        <h3>Fast Delivery</h3>
                        <p>Purchase today and get your products within 24-48 hours from the time our fulfilment team shipped out your orders.</p>
                    </div>

                    <div className="col">
                        <span className="material-symbols-outlined">
                            local_mall
                        </span>
                        <h3>SECURED SHOPPING</h3>
                        <p>We make sure that all transactions are private and protected. The information provided thru the checkout process is well-secured using a cutting edge encryption technology on our website.</p>
                    </div>

                    <div className="col">
                        <span className="material-symbols-outlined">
                            support_agent
                        </span>
                        <h3>CUSTOMER SUPPORT</h3>
                        <p>From providing top-quality products for our customers, we also provide an exceptional customer support with our trained Order Fulfillment Specialists by giving assistance from planning, to ordering, consumption and disposal.</p>
                    </div>
                </div>
            </div>
            <div className="block"></div>

        </section>
    )
}

export default About