import React from 'react';

const PageLoader = () => {
    return (
        <div className="page-loader">
            <div className="loader">
                Please wait...
            </div>
        </div>
    )
}

export default PageLoader