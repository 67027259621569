import React, { useContext, useEffect, useState } from 'react'
import ConfigContext from './context/ConfigContext'

const Questionnaire = () => {
    const { config } = useContext(ConfigContext);
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        if (config !== null) {
            setQuestions(config.questions);
        }
    }, [config]);

    return (
        <div className="group questionnaire">
            <h3>Questionnaire</h3>
            <ol>
                {questions.map(q => (<li key={q.id}><Question question={q} /></li>))}
            </ol>
        </div>
    )
}

const Question = ({ question }) => {
    return (
        <div className="form-group question">
            <label className="form-label">{question.question}</label>
            {question.type === "Text" ? (
                <textarea id={`question_${question.id}`} rows={3} className="form-control qnnaire" name={`question_${question.id}`} data-id={question.id}></textarea>
            ) : (
                <>
                    <label className="yesno">
                        <input type="radio" className="qnnaire" name={`question_${question.id}`} value="Yes" defaultChecked={question.defaultAnswer === "Yes"} data-id={question.id}/>
                        Yes
                    </label>

                    <label className="yesno">
                        <input type="radio" className="qnnaire" name={`question_${question.id}`} value="No" defaultChecked={question.defaultAnswer === "No"} data-id={question.id}/>
                        No
                    </label>
                </>
            )}
        </div>
    )
}

export default Questionnaire