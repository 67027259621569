import React, { useContext, useState } from 'react'
import { useFormik } from "formik";
import PageContext from './context/PageContext';
import { ContactSchema } from '../validators/checkout';
import { sendQuery } from './Actions';
import { useAlert } from 'react-alert';

const Contact = () => {
    const { setIsPageLoading } = useContext(PageContext);
    const alert = useAlert();

    const formik = useFormik({
        initialValues: {
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
        },
        validationSchema: ContactSchema,
        onSubmit: async (values) => {
            setIsPageLoading(true);
            const result = await sendQuery(values);
            if(result.success) {
                formik.resetForm();
                alert.success("Thank you for reaching us. Our agent will look into your inquiry and get back to you as soon as possible.");
            }
            setIsPageLoading(false);
        }
    });

    return (
        <section className="contact" id="contact">

            <div className="row">
                <div className="business-card col">
                    <h2>GET IN TOUCH</h2>
                    <p>
                        Our team is happy to answer your questions. Fill out the form and we'll
                        be in touch as soon as possible.
                    </p>
                </div>

                <div className="contact-form col">
                    <form noValidate onSubmit={formik.handleSubmit}>
                        <div className="grid">
                            <div className="grid-100">
                                <div className="form-group">
                                    <label className="form-label">First Name</label>
                                    <input value={formik.values.firstname} onChange={formik.handleChange} type="text" className="form-control" id="firstname" maxLength={50} />
                                    {formik.touched.firstname && Boolean(formik.errors.firstname) ?
                                        <p className="helperText">{formik.touched.firstname && formik.errors.firstname}</p>
                                        : null}
                                </div>
                            </div>

                            <div className="grid-100">
                                <div className="form-group">
                                    <label className="form-label">Last Name</label>
                                    <input value={formik.values.lastname} onChange={formik.handleChange} type="text" className="form-control" name="lastname" id="lastname" maxLength={50} />
                                    {formik.touched.lastname && Boolean(formik.errors.lastname) ?
                                        <p className="helperText">{formik.touched.lastname && formik.errors.lastname}</p>
                                        : null}
                                </div>
                            </div>

                            <div className="grid-100">
                                <div className="form-group">
                                    <label className="form-label">Email Address</label>
                                    <input type="email" value={formik.values.email} onChange={formik.handleChange} className="form-control" id="email" maxLength={50} />
                                    {formik.touched.email && Boolean(formik.errors.email) ?
                                        <p className="helperText">{formik.touched.email && formik.errors.email}</p>
                                        : null}
                                </div>
                            </div>

                            <div className="grid-100">
                                <div className="form-group">
                                    <label className="form-label">Phone Number</label>
                                    <input type="text" value={formik.values.phone} onChange={formik.handleChange} className="form-control" id="phone" maxLength={20}/>
                                    {formik.touched.phone && Boolean(formik.errors.phone) ?
                                        <p className="helperText">{formik.touched.phone && formik.errors.phone}</p>
                                        : null}
                                </div>
                            </div>

                            <div className="grid-100">
                                <div className="form-group">
                                    <label className="form-label">Subject</label>
                                    <input type="text" value={formik.values.subject} onChange={formik.handleChange} className="form-control" id="subject" maxLength={120}/>
                                    {formik.touched.subject && Boolean(formik.errors.subject) ?
                                        <p className="helperText">{formik.touched.subject && formik.errors.subject}</p>
                                        : null}
                                </div>
                            </div>

                            <div className="grid-100">
                                <div className="form-group">
                                    <label className="form-label">Message</label>
                                    <textarea rows={4} value={formik.values.message} onChange={formik.handleChange} className="form-control" id="message"></textarea>
                                    {formik.touched.message && Boolean(formik.errors.message) ?
                                        <p className="helperText">{formik.touched.message && formik.errors.message}</p>
                                        : null}
                                </div>
                            </div>

                            <div className="grid-100">
                                <div className="form-group btn-wrapper">
                                    <button type="submit" className="btn">
                                        SUBMIT
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default Contact