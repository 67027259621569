import React from 'react'

const InformedConsent = () => {
    return (
        <section className="terms">
            <div className="row">
                <h2>INFORMED CONSENT STATEMENT</h2>
            </div>

            <div className="row content">
                <div>
                    <p>By requesting medication through&nbsp;<strong>KITSAFE</strong>, I, the requestor, confirm the following truthful statements as if under oath and subject to penalties of perjury:</p>
                    <ul>
                        <li>I hereby release all of its employees and contractors including physicians from ANY AND ALL liability whatsoever associated or connected with my request for and use of prescription medication(s).</li>
                        <li>I am an adult and I am aware of the potential side effects associated with ALL medications; both prescribed and non-prescribed.</li>
                        <li>I have answered truthfully all of the medical questions on my questionnaire. I understand that no doctor, pharmacist, or administrative personnel can guarantee that the requested medication(s), even if prescribed, will provide the results I seek.</li>
                        <li>Additionally, I understand that even if prescribed, I may suffer adverse effects from the requested medication(s).</li>
                        <li>I am voluntarily requesting medication(s) of my own choice, at my own expense and my own liability, and assume all responsibility for the use of any medication(s).</li>
                        <li>I fully understand that it is my responsibility to have an annual physical examination, including any suggested lab tests, to ensure that I have no disease(s) that might make the medications inappropriate for my condition.</li>
                        <li>I further agree that I have consulted with my physician and/or pharmacist and hereby warrant that I am not taking any medications or combination of medications that are on the published list of medications that are contraindicated with these medications.</li>
                        <li>I further agree to immediately notify any doctor whose present care I am under that I have chosen to take medications so that they may advise me to continue or discontinue use. I understand that&nbsp;is unable to accept returns or issue refunds for any orders due to the fact that this is a prescription medication.</li>
                        <li>I am responsible for all customs, tariffs, and taxes applicable to my order. I authorize the contracted pharmacy from which I have ordered, to fill the prescription for the medication I am requesting. I understand the medication will be shipped within 1 to 2 business days depending on the paid shipping fees from the time that <strong>KITSAFE’s</strong> dispensary pharmacy shipped and fulfilled the order to the USPS.</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default InformedConsent