import React, { useContext, useEffect, useState } from 'react';
import ConfigContext from './context/ConfigContext';
import { useFormik } from "formik";
import { CheckoutSchema } from '../validators/checkout';
import { useNavigate } from "react-router-dom";
import { getCities, getStates, placeOrder } from "./Actions";
import Questionnaire from './Questionnaire';
import PageContext from './context/PageContext';
import { useAlert } from 'react-alert';

const Payment = () => {
    const { config } = useContext(ConfigContext);
    const { setIsPageLoading } = useContext(PageContext);

    const [order, setOrder] = useState(null);
    const [shippingMethods, setShippingMethods] = useState([]);
    const [billingStates, setBillingStates] = useState([]);
    const [billingCities, setBillingCities] = useState([]);
    const [shippingStates, setShippingStates] = useState([]);
    const [shippingCities, setShippingCities] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [readResponsiblity, setReadResponsibility] = useState(false);
    const [readConsent, setReadConsent] = useState(false);

    const navigate = useNavigate();
    const alert = useAlert();

    useEffect(() => {
        if (config !== null && !loaded) {
            const methods = config.settings.shippingMethods;
            setShippingMethods(methods);

            const queryParams = new URLSearchParams(window.location.search);
            const pid = queryParams.get("pid");

            if (pid === null) {
                navigate("/");
                return;
            }

            for (const v of config.products) {
                const p = v.variants.find(x => x.id === pid);
                if (typeof (p) !== "undefined") {
                    setOrder({
                        id: p.id,
                        name: p.name,
                        price: p.price,
                        images: v.images,
                        description: v.description,
                        category: v.category,
                        type: p.type,
                        count: p.count,
                        dosage: p.dosage,
                        questions: v.questions
                    });
                    break;
                }
            }

            initStates();
            setLoaded(true);

            window.scrollTo(0,0);
        }
    }, [config]);

    const initStates = async () => {
        setIsPageLoading(true);
        const result = await getStates();
        if (result.success) {
            setBillingStates(result.data);
            setShippingStates(result.data);
        }
        setIsPageLoading(false);
    }

    const handleStateChange = async (event, type) => {
        const value = event.target.value;
        const result = await getCities(value);
        if (result.success) {
            formik.setFieldValue(`${type}State`, value);
            if (type === "billing") {
                setBillingCities(result.data);
            } else {
                setShippingCities(result.data);
            }
        }
    }

    const formik = useFormik({
        initialValues: {
            firstname: "",
            middlename: "",
            lastname: "",
            gender: "MALE",
            birthdate: "",
            email: "",
            phone: "",
            billingState: "",
            billingCity: "",
            billingStreet: "",
            billingZipCode: "",
            isShippingSameToBilling: true,
            shippingState: "",
            shippingCity: "",
            shippingStreet: "",
            shippingZipCode: "",
            questionnaire: [],
            shippingMethod: 'shipping_cost_priority',
            product: {}
        },
        validationSchema: CheckoutSchema,
        onSubmit: async (values) => {
            setIsPageLoading(true);

            const qna = [];
            const qnaires = document.getElementsByClassName("qnnaire");

            Array.from(qnaires).forEach(element => {
                const q = {
                    question_id: element.getAttribute("data-id"),
                    value: element.value
                }
                qna.push(q);
            });

            values.questionnaire = qna;
            values.product = order;
            const result = await placeOrder(values);
            if(result.success) {
                formik.resetForm();
                setBillingCities([]);
                setShippingCities([]);
                alert.success("Your order has been placed. Our agent will call you to confirm the order. Thank you.");
                navigate("/");
            }
            setIsPageLoading(false);
        }
    });

    const handleSubmit = async () => {
        formik.submitForm();
    }

    const handleReadTerms = async (event, type) => {
        const checked = event.target.checked;
        if(type === "responsibility") {
            setReadResponsibility(checked);
        }else {
            setReadConsent(checked);
        }
    }

    return (
        <section className="payment" id="payment">
            <div className="row">
                <h2>ORDER CHECKOUT</h2>
                <p>Please fill in the fields below and click Place order to complete your purchase.</p>
            </div>

            <form className="row checkout-form" noValidate autoComplete='off' name="checkout-form" onSubmit={formik.handleSubmit}>
                <div className="form">
                    <div className="group">
                        <h3>Billing Information</h3>

                        <div className="grid">
                            <div className="grid-33">
                                <div className="form-group">
                                    <label className="form-label">First Name</label>
                                    <input value={formik.values.firstname} onChange={formik.handleChange} type="text" className="form-control" id="firstname" maxLength={50}/>
                                    {formik.touched.firstname && Boolean(formik.errors.firstname) ?
                                        <p className="helperText">{formik.touched.firstname && formik.errors.firstname}</p>
                                        : null}
                                </div>
                            </div>

                            <div className="grid-33">
                                <div className="form-group">
                                    <label className="form-label">Middle Name</label>
                                    <input value={formik.values.middlename} onChange={formik.handleChange} type="text" className="form-control" id="middlename" maxLength={50}/>
                                    {formik.touched.middlename && Boolean(formik.errors.middlename) ?
                                        <p className="helperText">{formik.touched.middlename && formik.errors.middlename}</p>
                                        : null}
                                </div>
                            </div>

                            <div className="grid-33">
                                <div className="form-group">
                                    <label className="form-label">Last Name</label>
                                    <input value={formik.values.lastname} onChange={formik.handleChange} type="text" className="form-control" name="lastname" id="lastname" maxLength={50}/>
                                    {formik.touched.lastname && Boolean(formik.errors.lastname) ?
                                        <p className="helperText">{formik.touched.lastname && formik.errors.lastname}</p>
                                        : null}
                                </div>
                            </div>
                        </div>

                        <div className="grid">
                            <div className="grid-50">
                                <div className="form-group">
                                    <label className="form-label">Gender</label>
                                    <select value={formik.values.gender} onChange={formik.handleChange} className="form-control" id="gender">
                                        <option value="MALE">Male</option>
                                        <option value="FEMALE">Female</option>
                                    </select>
                                    {formik.touched.gender && Boolean(formik.errors.gender) ?
                                        <p className="helperText">{formik.touched.gender && formik.errors.gender}</p>
                                        : null}
                                </div>
                            </div>

                            <div className="grid-50">
                                <div className="form-group">
                                    <label className="form-label">Birth Date</label>
                                    <input value={formik.values.birthdate} onChange={formik.handleChange} type="date" className="form-control" id="birthdate" maxLength={50}/>
                                    {formik.touched.birthdate && Boolean(formik.errors.birthdate) ?
                                        <p className="helperText">{formik.touched.birthdate && formik.errors.birthdate}</p>
                                        : null}
                                </div>
                            </div>
                        </div>

                        <div className="grid">
                            <div className="grid-50">
                                <div className="form-group">
                                    <label className="form-label">Email Address</label>
                                    <input type="email" value={formik.values.email} onChange={formik.handleChange} className="form-control" id="email" maxLength={50}/>
                                    {formik.touched.email && Boolean(formik.errors.email) ?
                                        <p className="helperText">{formik.touched.email && formik.errors.email}</p>
                                        : null}
                                </div>
                            </div>

                            <div className="grid-50">
                                <div className="form-group">
                                    <label className="form-label">Phone Number</label>
                                    <input type="text" value={formik.values.phone} onChange={formik.handleChange} className="form-control" id="phone" maxLength={20}/>
                                    {formik.touched.phone && Boolean(formik.errors.phone) ?
                                        <p className="helperText">{formik.touched.phone && formik.errors.phone}</p>
                                        : null}
                                </div>
                            </div>
                        </div>

                        <div className="grid">
                            <div className="grid-50">
                                <div className="form-group">
                                    <label className="form-label">Billing State</label>
                                    <select value={formik.values.billingState} onChange={e => handleStateChange(e, "billing")} className="form-control" id="billingState">
                                        <option value="">Select State</option>
                                        {billingStates.length > 0 ? (billingStates.map(s => (
                                            <option key={s.id} value={s.id}>{s.name}</option>
                                        ))) : null}
                                    </select>
                                    {formik.touched.billingState && Boolean(formik.errors.billingState) ?
                                        <p className="helperText">{formik.touched.billingState && formik.errors.billingState}</p>
                                        : null}
                                </div>
                            </div>

                            <div className="grid-50">
                                <div className="form-group">
                                    <label className="form-label">Billing City</label>
                                    <select value={formik.values.billingCity} onChange={formik.handleChange} className="form-control" id="billingCity">
                                        {billingCities.length > 0 ? (billingCities.map(s => (
                                            <option key={s.id} value={s.id}>{s.name}</option>
                                        ))) : null}
                                    </select>
                                    {formik.touched.billingCity && Boolean(formik.errors.billingCity) ?
                                        <p className="helperText">{formik.touched.billingCity && formik.errors.billingCity}</p>
                                        : null}
                                </div>
                            </div>
                        </div>

                        <div className="grid">
                            <div className="grid-50">
                                <div className="form-group">
                                    <label className="form-label">Billing Street</label>
                                    <input value={formik.values.billingStreet} onChange={formik.handleChange} className="form-control" id="billingStreet" maxLength={50}/>
                                    {formik.touched.billingStreet && Boolean(formik.errors.billingStreet) ?
                                        <p className="helperText">{formik.touched.billingStreet && formik.errors.billingStreet}</p>
                                        : null}
                                </div>
                            </div>

                            <div className="grid-50">
                                <div className="form-group">
                                    <label className="form-label">Billing Zip Code</label>
                                    <input value={formik.values.billingZipCode} onChange={formik.handleChange} className="form-control" id="billingZipCode" maxLength={5}/>
                                    {formik.touched.billingZipCode && Boolean(formik.errors.billingZipCode) ?
                                        <p className="helperText">{formik.touched.billingZipCode && formik.errors.billingZipCode}</p>
                                        : null}
                                </div>
                            </div>
                        </div>

                        <div className="grid">
                            <div className="grid-100">
                                <div className="form-group">
                                    <label className="form-label">
                                        <input
                                            value={formik.values.isShippingSameToBilling}
                                            onChange={formik.handleChange}
                                            type="checkbox"
                                            id="isShippingSameToBilling"
                                            defaultChecked={formik.values.isShippingSameToBilling}
                                        />
                                        &nbsp; Shipping address same as billing address?
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {!formik.values.isShippingSameToBilling ? (
                        <div className="group">
                            <h3>Shipping Information</h3>
                            <div className="grid">
                                <div className="grid-50">
                                    <div className="form-group">
                                        <label className="form-label">Shipping State</label>
                                        <select value={formik.values.shippingState} onChange={e => handleStateChange(e, "shipping")} className="form-control" id="shippingState">
                                            <option value="">Select State</option>
                                            {shippingStates.length > 0 ? (shippingStates.map(s => (
                                                <option key={s.id} value={s.id}>{s.name}</option>
                                            ))) : null}
                                        </select>
                                        {formik.touched.shippingState && Boolean(formik.errors.shippingState) ?
                                            <p className="helperText">{formik.touched.shippingState && formik.errors.shippingState}</p>
                                            : null}
                                    </div>
                                </div>

                                <div className="grid-50">
                                    <div className="form-group">
                                        <label className="form-label">Shipping City</label>
                                        <select value={formik.values.shippingCity} onChange={formik.handleChange} className="form-control" id="shippingCity">
                                            {shippingCities.length > 0 ? (shippingCities.map(s => (
                                                <option key={s.id} value={s.id}>{s.name}</option>
                                            ))) : null}
                                        </select>
                                        {formik.touched.shippingCity && Boolean(formik.errors.shippingCity) ?
                                            <p className="helperText">{formik.touched.shippingCity && formik.errors.shippingCity}</p>
                                            : null}
                                    </div>
                                </div>
                            </div>

                            <div className="grid">
                                <div className="grid-50">
                                    <div className="form-group">
                                        <label className="form-label">Shipping Street</label>
                                        <input value={formik.values.shippingStreet} onChange={formik.handleChange} className="form-control" id="shippingStreet" maxLength={50}/>
                                        {formik.touched.shippingStreet && Boolean(formik.errors.shippingStreet) ?
                                            <p className="helperText">{formik.touched.shippingStreet && formik.errors.shippingStreet}</p>
                                            : null}
                                    </div>
                                </div>

                                <div className="grid-50">
                                    <div className="form-group">
                                        <label className="form-label">Shipping Zip Code</label>
                                        <input value={formik.values.shippingZipCode} onChange={formik.handleChange} className="form-control" id="shippingZipCode" maxLength={5}/>
                                        {formik.touched.shippingZipCode && Boolean(formik.errors.shippingZipCode) ?
                                            <p className="helperText">{formik.touched.shippingZipCode && formik.errors.shippingZipCode}</p>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    <Questionnaire />
                </div>

                <div className="summary">
                    <h3>Order Summary</h3>

                    <div className="order">
                        {order !== null ? (
                            <>
                                <img src={order.images[0]} crossOrigin="anonymous" />
                                <div className="details">
                                    <p>
                                        {order.name} <br />
                                        {order.count} {order.type}/ {order.dosage} <br />
                                        1 x {config.settings.currency}{Number(order.price).toFixed(2)}
                                    </p>
                                </div>
                            </>
                        ) : null}
                    </div>

                    <div className="total">
                        <h3>Total: {order !== null ? config.settings.currency + Number(order.price).toFixed(2) : null}</h3>
                    </div>

                    <div className="shipping">
                        <h3>Shipping Method</h3>
                        {shippingMethods.length > 0 ? shippingMethods.map(sm => (
                            <label className="form-label" key={sm.value}>
                                <input type="radio" name="shipping_method" value={sm.value} defaultChecked={sm.value === formik.values.shippingMethod} />
                                {sm.label}
                            </label>
                        )) : null}
                    </div>

                    <div className="consent">
                        <label className="form-label">
                            <input type="checkbox" onChange={e => {handleReadTerms(e, "responsibility")}} />
                            I have read, understand, and agree with the <a href="/statement-of-customer-responsibility" target="_blank">Customer Responsibility Statement</a>.
                        </label>

                        <label className="form-label">
                            <input type="checkbox" onChange={e => {handleReadTerms(e, "consent")}} />
                            I have read, understand, and agree with the <a href="/informed-consent-statement" target="_blank">Informed Consent Statement</a>.
                        </label>
                    </div>

                    <div className="form-group btn-wrapper">
                        <button type="button" className={`btn ${(!readResponsiblity || !readConsent) ? 'disabled': ''}`} onClick={handleSubmit} disabled={!readResponsiblity || !readConsent}>
                            PLACE ORDER
                        </button>
                    </div>
                </div>
            </form>
        </section>
    )
}

export default Payment