import React, { useContext, useEffect, useState } from 'react';
import logo from "../assets/logo.png";
import ConfigContext from './context/ConfigContext';
import { today } from './helpers/DateTimeHelper';

const Footer = () => {
    const { config } = useContext(ConfigContext);

    const [support, setSupport] = useState(null);
    const year = today('YYYY');

    useEffect(() => {
        if(config !== null) {
            setSupport(config.settings.support);        
        }
    },[config]);

    return (
        <div className="footer">
            <div className="row">
                <div className="logo col">
                    <img src={logo} alt="logo" />
                    <span>&copy; {year}</span>
                </div>
                <div className="contact-us col">
                    <div className="contact">
                        <h3>CONTACT US</h3>
                        <p>
                            <span className="material-symbols-outlined">
                                home
                            </span>
                            {support?.companyAddress}
                        </p>
                        <p>
                            <span className="material-symbols-outlined">
                                mail
                            </span>
                            {support?.emailAddress}
                        </p>
                        <p>
                            <span className="material-symbols-outlined">
                                call
                            </span>
                            {support?.contactNumber}
                        </p>
                    </div>

                    <div className="links">
                        <h3>TERMS</h3>
                        <a href='/statement-of-customer-responsibility'>Terms and Conditions</a>
                        <a href='/legal-disclaimer'>Legal Disclaimer</a>
                        <a href='/privacy-policy'>Privacy Policy</a>
                        {/* <a href='/return-policy'>Return Policy</a>
                        <a href='/refund-policy'>Refund Policy</a> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer