import * as yup from "yup";

export const CheckoutSchema = yup.object({
    firstname: yup
        .string()
        .required("First name is required"),
    middlename: yup
        .string()
        .required("Middle name is required"),
    lastname: yup
        .string()
        .required("Last name is required"),
    gender: yup
        .string()
        .required("Gender is required"),
    birthdate: yup
        .date()
        .required("Birth date is required"),
    email: yup
        .string()
        .email("Please enter valid email")
        .required("Email is required"),
    phone: yup
        .string()
        .required("Phone is required"),
    billingState: yup
        .string()
        .required("Billing state is required"),
    billingCity: yup
        .string()
        .required("Billing City is required"),
    billingStreet: yup
        .string()
        .required("Billing street is required"),
    billingZipCode: yup
        .string()
        .required("Zip code is required"),
    isShippingSameToBilling: yup
        .boolean()
        .default(true),
    shippingState: yup
        .string()
        .nullable()
        .when("isShippingSameToBilling", {
            is: false,
            then: yup.string().required("Shipping state is required")
        }),
    shippingCity: yup
        .string()
        .when("isShippingSameToBilling", {
            is: false,
            then: yup.string().required("Shipping city is required")
        }),
    shippingStreet: yup
        .string()
        .when("isShippingSameToBilling", {
            is: false,
            then: yup.string().required("Shipping street is required")
        }),
    shippingZipCode: yup
        .string()
        .when("isShippingSameToBilling", {
            is: false,
            then: yup.string().required("Shipping zip code is required")
        }),
    questionnaire: yup
        .array()
        .default([]),
    shippingMethod: yup
        .string(),
    product: yup
        .object()
        .required("Product is required"),
});

export const ContactSchema = yup.object({
    firstname: yup
        .string()
        .required("First name is required"),
    lastname: yup
        .string()
        .required("Last name is required"),
    email: yup
        .string()
        .email("Please enter valid email")
        .required("Email is required"),
    phone: yup
        .string()
        .required("Phone is required"),
    subject: yup
        .string()
        .required("Message is required"),
    message: yup
        .string()
        .required("Message is required"),
});