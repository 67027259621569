import sha256 from "crypto-js/sha256";
import { today } from "./helpers/DateTimeHelper";

export const tryFetch = async (params) => {
    const appKey = process.env.REACT_APP_API_KEY;
    const secret = process.env.REACT_APP_API_SECRET;
    const hash = btoa(`${appKey}:${secret}`);

    const headers = {
        'Authorization': `Bearer ${hash}`,
        'Content-Type': 'application/json',
        'X-AppKey': appKey,
        'X-Signature': await generateSign(appKey, params.payload),
    }
    
    const method = typeof (params.method) !== 'undefined' ? params.method : 'POST';
    const url = process.env.REACT_APP_NODE_ENV === 'development' ?
        params.url : process.env.REACT_APP_SERVER_URL + params.url;

    const response = await fetch(url, {
        method: method,
        headers: headers,
        body: method === 'POST' ? JSON.stringify(params.payload) : undefined
    });

    const json = await response.json();
    return json;
}

export const generateSign = async (appKey, data) => {
    const concat = `${JSON.stringify(data)}|${today()}|${appKey}`;
    return sha256(concat).toString();
}
