import React, { useContext, useEffect, useState } from 'react';
import ConfigContext from './context/ConfigContext';
import Product from './Product';

const Featured = () => {
    const { config } = useContext(ConfigContext);

    const [featured, setFeatured] = useState([]);
    const [currency, setCurrency] = useState("");

    useEffect(() => {
        if(config !== null) {
            setFeatured(config.featured);        
            setCurrency(config.settings.currency);
        }
    },[config]);

    return (
        <section className="featured" id="featured">

            <div className="row">
                <h2>FEATURED PRODUCTS</h2>
            </div>

            <div className="row product-list">
                {featured.length > 0 ? (featured.map(f => (<Product product={f} currency={currency} key={f.id} />))) : null}
            </div>
        </section>
    )
}

export default Featured