import React from 'react'
import Featured from "./Featured";
import Contact from "./Contact";
import About from "./About";
import logo from "../assets/logo.png";

const Home = () => {
    return (
        <div className="layout">
            <div className="banner">
                <img src={logo} alt='logo' />
            </div>
            <Featured />
            <About />
            <Contact />
        </div>
    )
}

export default Home