import { createContext } from "react";

const config = {
    banner:[],
    featured: [],
    products: [],
    settings: {
        currency: "",
        timezone: "",
        support: {
            emailAddress: "",
            contactNumber: "",
            companyAddress: ""
        },
        shippingMethods: []
    }
}

const ConfigContext = createContext(config)
export default ConfigContext;