import React from 'react'

const Responsibility = () => {
    return (
        <section className="terms">
            <div className="row">
                <h2>STATEMENT OF CUSTOMER RESPONSIBILITY</h2>
            </div>

            <div className="row content">
                <div>
                    <p>By ordering products from&nbsp;<strong>LYRACARE</strong>, I, the requestor, confirm the following statements to be true and complete, as if I am placed under oath and subject to penalties of perjury:</p>
                    <ul>
                        <li>I am at least 18 years old and an adult capable of entering legal contracts.</li>
                        <li>The laws in my geographical address permit the delivery of the requested medication(s).</li>
                        <li>All questions asked of me during the medication request on this website have been answered truthfully and completely.</li>
                        <li>I will not distribute or sell the requested medication(s) to others.</li>
                        <li>I have had a recent physical examination by a licensed medical physician. Based on the results of my physical and medical history, my doctor has informed me that I have no problems using the requested medication(s).</li>
                        <li>I know that all medication(s) have certain risks and I am ready to seek medical attention should I encounter them.</li>
                        <li>I will contact my doctor for medical assistance in case I have any complications, issues, or questions regarding the requested medication(s).</li>
                        <li>Knowing all the risks associated with the requested medication(s), I consent to treatment.</li>
                        <li>I understand the benefits, side effects, and risks of the requested prescription medication(s). I have read additional literature about this and have no additional questions.</li>
                        <li>I have used the requested mediation(s) in the past while under a licensed doctor's supervision. My doctor has advised me that the requested medication(s) is appropriate for my condition.</li>
                        <li>I am requesting prescription medication for my own personal medical purposes only.</li>
                        <li>I request that a US Licensed Medical Doctor assist my Local Medical Doctor by prescribing the requested medication(s).</li>
                        <li>I request the prescribing doctor to allow the fulfillment of the requested medication(s) by a US-licensed pharmacy.</li>
                        <li>I do not request the prescribing doctor to replace the opinion of my local physician.</li>
                        <li>I am requesting just the needed amount of medication(s) for my condition and I am not attempting to create a reserve or stockpile of medication.</li>
                        <li>I will not take any other medication(s), including "over-the-counter" medication, without prior consultation and approval from my pharmacist.</li>
                        <li>I am the authorized cardholder of the credit card used for payment of the requested medication.</li>
                        <li>I have provided all pertinent information concerning my health and medical history so that the pharmacist and prescribing doctor may properly review my request for medication.</li>
                    </ul>
            </div>
        </div>
        </section >
    )
}

export default Responsibility