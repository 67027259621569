import Footer from "./components/Footer";
import Header from "./components/Header";
import Payment from "./components/Payment"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import ConfigContext from "./components/context/ConfigContext";
import { useEffect, useState } from "react";
import { handleInit } from "./components/Actions";
import Responsibility from "./components/terms/Responsibility";
import InformedConsent from "./components/terms/InformedConsent";
import PageContext from "./components/context/PageContext";
import PageLoader from "./components/PageLoader";
import { positions, Provider, types } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import ProductContext from "./components/context/ProductContext";
import PrivacyPolicy from "./components/terms/PrivacyPolicy";
import ReturnPolicy from "./components/terms/ReturnPolicy";
import RefundPolicy from "./components/terms/RefundPolicy";
import LegalDisclaimer from "./components/terms/LegalDisclaimer";

const options = {
	timeout: 10000,
	position: positions.BOTTOM_RIGHT,
};

function App() {
	const [config, setConfig] = useState(null);
	const [isPageLoading, setIsPageLoading] = useState(false);
	const [category, setCategory] = useState(null);

	useEffect(() => {
		initialize();
	}, []);

	const initialize = async () => {
		setIsPageLoading(true);
		const result = await handleInit();
		setConfig(result);
		setIsPageLoading(false);
	}

	return (
		<ConfigContext.Provider value={{ config }}>
			<PageContext.Provider value={{ setIsPageLoading }}>
				<ProductContext.Provider value={{ category, setCategory }}>
					<div className="body">
						{isPageLoading ? <PageLoader /> : null}
						<Provider template={AlertTemplate} {...options}>
							<div className="wrapper">
								<Router>
									<Header />
									<Routes>
										<Route path="/payment" element={<Payment />} />
										<Route path="/statement-of-customer-responsibility" element={<Responsibility />} />
										<Route path="/informed-consent-statement" element={<InformedConsent />} />
										<Route path="/privacy-policy" element={<PrivacyPolicy />} />
										{/* <Route path="/return-policy" element={<ReturnPolicy />} />
										<Route path="/refund-policy" element={<RefundPolicy />} /> */}
										<Route path="/legal-disclaimer" element={<LegalDisclaimer />} />
										<Route path="/" element={<Home />} />
									</Routes>
								</Router>
								<div className="pusher"></div>
							</div>
						</Provider>
						<Footer />
					</div>
				</ProductContext.Provider>
			</PageContext.Provider>
		</ConfigContext.Provider>
	);
}

export default App;
